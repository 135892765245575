import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import CarouselLogo from '../Components/CarouselLogo';
import ScrollToTop from './ScrollToTop';
import AreasExpertise from '../Components/AreasExpertise';
import RoiFocused from '../Components/RoiFocused';
import CountUpSection from '../Components/CountUpSection';
import SetMeeting from '../Components/SetMeeting';
import { HashLink as Link } from "react-router-hash-link";
import ServicesDetailsMetaData from '../Data/ServicesDetailsMetaData';
import '../Components/css/Srvsales.css';
/*
*********************************************************************************************************
*  @Parent Component           :    
*  @React Component Name       :    Sales
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       service section to Section
********************************************************************************************************
*/
export default function Sales() {

	const [posts, setPosts] = useState([]);
	const [searchParams] = useSearchParams();
	let name = searchParams.get('page');
	name = name.replace(/%26/g,'&')
	const [SerLogo, setServiceLogo] = useState('')
	const [arrayOfItems, setArrayOfItems] = useState({
		id: '',
		items: [],
	});
/*
*********************************************************************************************************
*   @React Component Name       :       Sales
*   @Method                     :       fetchPosts
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       fetch the data from the server for the service page 
*********************************************************************************************************
*/
	const fetchPosts = () => {
		fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/services`)
			.then((res) => res.json())
			.then((resData) => {
				if (resData.length > 0) {
					const firstCard = resData.find((item) => item.name === name);

					if (firstCard) {
						const match = firstCard.content.match(/<li>.*?<\/li>/g);
						const items = match ? match.map((m) => m.slice(4, -5)) : [];
						setArrayOfItems({
							id: firstCard.name,
							items: items,
						});
					}
				}
				setPosts(resData);
			})
			.catch((err) => {
				console.error('Error fetching blog posts:', err);
			});
	};

	/*
*********************************************************************************************************
*   @React Component Name       :       Sales
*   @Method                     :       setServiceImage
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       set the service image as the path of the url 
*********************************************************************************************************
*/

	const setServiceImage = () => {
		ServicesDetailsMetaData.map((ServicesDetails) => {
			ServicesDetails.path === name ? setServiceLogo(ServicesDetails.img) : setServiceLogo(null)
		})
	}

	/*
*********************************************************************************************************
*   @React Component Name       :       Sales
*   @Method                     :       useEffect
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       initialy call 
*********************************************************************************************************
*/

	useEffect(() => {
		fetchPosts();
		setServiceImage()
	}, [name]);

	return (
		<div>
			{posts.map((item) =>
				item.name === name ? (
					<div key={item.id}>
						<div className="headingbg">
							<div className="sevcontainer">
								<div className="content-heading-div">
									<div className="heading_details">
										<div className='service-img-container'>
											<img src='/assets/salesforce-with-type-logo.svg' alt="" className='salesforcelogo' />
											<div className='service-icon'>
												<img key={item.serviceIcon} src={item.serviceIcon} alt="" />
											</div>
										</div>
										<div>
											<h3 >
												<span style={{color:"#0d9dda"}}>{item.name}</span>
											</h3>
										</div>
										<div >
											<li dangerouslySetInnerHTML={{ __html: item.content }}></li>
										</div>
										<Link to="/#contact">
											<button className="service-contact-btn" variant="primary">Meet an Expert
											</button>
										</Link>
									</div>

								</div>
							</div>
						</div>
						<CarouselLogo />
						<ScrollToTop />
						<AreasExpertise AreasExpertise={item.areaOfExpertises} id={item.id} />
						<RoiFocused />
						<CountUpSection />
						<SetMeeting />
					</div>
				) : null
			)}
		</div>
	);
}