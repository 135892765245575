import React, { Component } from 'react';
import Input from '../../components/Form/Input/Input';
import Button from '../../components/Button/Button';
import Auth from '../Auth/Auth';
import Post from '../../components/Feed/Post/Post';
import Loader from '../../components/Loader/Loader';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

/*
*********************************************************************************************************
*  @Parent Component           :    App Admin panel 
*  @React Component Name       :    Achievements
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    04-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Achievement Admin panel
********************************************************************************************************
*/

class Achievements extends Component {
    /*
    *********************************************************************************************************
    *   @React Component Name       :       Achievements
    *   @Method                     :       constructor
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       Declare all variable or input variale 
    *********************************************************************************************************
    */

    constructor(props) {
        super(props);
        this.state = {
            submitBtn: false,
            achievementForm: {
                name: {
                    value: '',
                    valid: false,
                    validators: [],
                },
                number: {
                    value: '',
                    valid: false,
                    validators: [],
                },
            },
            formShow: false,
            posts: [],
            postsLoading: false,
            editPostBtn: false,
            editPostId: null,
        };
    }

    /*
*********************************************************************************************************
*   @React Component Name       :       Achievements
*   @Method                     :       inputChangeHandler
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this function handel all input changes in the page 
*********************************************************************************************************
*/

    inputChangeHandler = (input, value) => {
        this.setState((prevState) => {
            let isValid = true;
            for (const validator of prevState.achievementForm[input].validators) {
                isValid = isValid && validator(value);
            }
            const updatedForm = {
                ...prevState.achievementForm,
                [input]: {
                    ...prevState.achievementForm[input],
                    valid: isValid,
                    value: value,
                },
            };
            let formIsValid = true;
            for (const inputName in updatedForm) {
                formIsValid = formIsValid && updatedForm[inputName].valid;
            }
            return {
                achievementForm: updatedForm,
                formIsValid: formIsValid,
            };
        });
    };

    /*
  *********************************************************************************************************
  *   @React Component Name       :       Achievements
  *   @Method                     :       toggleForm
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :        this button show the form or toggle it 
  *********************************************************************************************************
  */

    toggleForm = () => {
        this.setState((prevState) => ({
            formShow: !prevState.formShow,
        }));
    };

    /*
  *********************************************************************************************************
  *   @React Component Name       :       Achievements
  *   @Method                     :       componentDidMount
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       call when the any update or initialy render on this page 
  *********************************************************************************************************
  */

    componentDidMount() {
        this.fetchPosts();
    }

    /*
  *********************************************************************************************************
  *   @React Component Name       :       Achievements
  *   @Method                     :       setNullAllInputField
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       set all input value null 
  *********************************************************************************************************
  */

    setNullAllInputField = () => {
        this.setState((prevState) => ({
            achievementForm: {
                ...prevState.achievementForm,
                name: {
                    ...prevState.achievementForm.name,
                    value: '',
                },

                number: {
                    ...prevState.achievementForm.number,
                    value: '',
                },

            },
        }));
    };

    /*
  *********************************************************************************************************
  *   @React Component Name       :       Achievements
  *   @Method                     :       onAchievements
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       this method create the Achievements or update the existing Achievements and save on the DB
  *********************************************************************************************************
  */

    onAchievements = (event) => {
        event.preventDefault();
        const { name, number } = this.state.achievementForm;
        const requestData = {
            name: name.value,
            number: number.value,
            createdBy: localStorage.getItem('userId'),
            updatedBy: localStorage.getItem('userId'),
        };

        if (this.state.editPostBtn) {
            fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/achievements/` + this.state.editPostId, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then(res => {
                    return res
                })
                .then(data => {
                    if (data.status === 500) {
                        alert("server error")
                    }
                    this.toggleForm();
                    this.fetchPosts();
                    this.setNullAllInputField();
                    this.setState({ selectedServices: [] });
                    this.setState({
                        editPostBtn: false,
                        editPostId: null,
                    });
                })
                .catch(err => {
                    console.error('Error updating achievements:', err);
                });
        } else {
            fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/achievements`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then(res => res.json())
                .then(data => {
                    this.toggleForm();
                    this.fetchPosts();
                    this.setNullAllInputField();
                })
                .catch(err => {
                    console.error('Error creating achievements:', err);
                });
        }
    };

    /*
  *********************************************************************************************************
  *   @React Component Name       :       Achievements
  *   @Method                     :       fetchPosts
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       fetch all Achievements from the backend 
  *********************************************************************************************************
  */

    fetchPosts = () => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/achievements`)
            .then((res) => res.json())
            .then((resData) => {
                this.setState({ posts: resData, postsLoading: false });
            })
            .catch((err) => {
                console.error('Error fetching blog posts:', err);
                this.setState({ postsLoading: false });
            });
    };

    /*
  *********************************************************************************************************
  *   @React Component Name       :       Achievements
  *   @Method                     :       deletePostHandler
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       This method use the delete the existing Achievements 
  *********************************************************************************************************
  */

    deletePostHandler = (postId) => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/achievements/${postId}`, {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + this.props.token,
            },
        })
            .then((res) => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Deleting a post failed!');
                }
                console.log('Post deleted successfully');
                this.fetchPosts();
                return res.json();
            })
            .catch((err) => {
                console.error('Error deleting post:', err);
                this.setState({ postsLoading: false });
            });
    };

    /*
  *********************************************************************************************************
  *   @React Component Name       :       Achievements
  *   @Method                     :       inputBlurHandler
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       it blure the input if any error
  *********************************************************************************************************
  */

    inputBlurHandler = (input) => {
        this.setState((prevState) => ({
            achievementForm: {
                ...prevState.achievementForm,
                [input]: {
                    ...prevState.achievementForm[input],
                    touched: true,
                },
            },
        }));
    };

    /*
  *********************************************************************************************************
  *   @React Component Name       :       Achievements
  *   @Method                     :       fetchSinglePost
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       it fetch the single post accoding to the id 
  *********************************************************************************************************
  */

    fetchSinglePost = (postId) => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/achievements/` + postId)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Failed to fetch post data.');
                }
                return res.json();
            })
            .then(resData => {
                this.setState(prevState => ({
                    achievementForm: {
                        ...prevState.achievementForm,
                        name: {
                            ...prevState.achievementForm.name,
                            value: resData.name
                        },
                        number: {
                            ...prevState.achievementForm.number,
                            value: resData.number
                        },
                    },
                }));
            })
            .catch(error => {
                alert(error.message);
                console.error('Error fetching post data:', error);
            })
            .finally(() => {
                this.setState({ postsLoading: false });
            });
    };

    /*
*********************************************************************************************************
*   @React Component Name       :       Achievements
*   @Method                     :       editPost
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this button open input field and load existing data in the input field 
*********************************************************************************************************
*/

    editPost = postId => {
        this.setState({
            editPostBtn: true,
            editPostId: postId
        }, () => {
            this.fetchSinglePost(postId);
            this.toggleForm();
        });
    };

    render() {
        return (
            <div className="main-div-login">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '1rem 0 ',
                    }}
                >
                    <button onClick={this.toggleForm} className='buttonCreatePost'>
                        {this.state.formShow ? 'Close New Post' : 'Create New Post'}
                    </button>
                </div>
                {this.state.formShow && (
                    <Auth>
                        <form onSubmit={this.onAchievements}>
                            <Input
                                id="name"
                                label="Achievement Name"
                                type="text"
                                control="input"
                                onChange={this.inputChangeHandler}
                                value={this.state.achievementForm['name'].value}
                                valid={this.state.achievementForm['name'].valid}
                            />
                            <Input
                                id="number"
                                label="Achievement Number"
                                type="text"
                                control="input"
                                onChange={this.inputChangeHandler}
                                value={this.state.achievementForm['number'].value}
                                valid={this.state.achievementForm['number'].valid}
                            />


                            {this.state.editPostBtn ? (
                                <Button design="raised" type="submit" loading={this.props.loading}>
                                    Update Post
                                </Button>
                            ) : (
                                <Button design="raised" type="submit" loading={this.props.loading}>
                                    Create Post
                                </Button>
                            )}
                        </form>
                    </Auth>
                )}
                <Auth>
                    {this.state.postsLoading && <Loader />}
                    {this.state.posts.length <= 0 && !this.state.postsLoading ? (
                        <p style={{ textAlign: 'center' }}>No posts found.</p>
                    ) : null}
                    {this.state.posts.map((post) => (
                        <Post
                            key={post.id}
                            id={post.id}
                            title={post.name}
                            content={post.number}
                            onStartEdit={() => this.editPost(post.id)}
                            onDelete={() => this.deletePostHandler(post.id)}
                        />
                    ))}
                </Auth>
            </div>
        );
    }
}

export default Achievements;


