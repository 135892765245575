import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

const Banner = () => {

	const [loopNum, setLoopNum] = useState(0);
	const [isDeleting, setIsDeleting] = useState(false);
	const [text, setText] = useState('');
	const [delta, setDelta] = useState(300 - Math.random() * 100);
	const [index, setIndex] = useState(1);
	const toRotate = ['Transform Digitally', 'Migrate Digitally'];
	const period = 2000;

	useEffect(() => {
		let ticker = setInterval(() => {
			tick();
		},
			delta);
		return () => { clearInterval(ticker) };
	}, [text])

	const tick = () => {
		let i = loopNum % toRotate.length;
		let fullText = toRotate[i];
		let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);
		setText(updatedText);
		if (isDeleting) {
			setDelta(prevDelta => prevDelta / 2);
		}

		if (!isDeleting && updatedText === fullText) {
			setIsDeleting(true);
			setIndex(prevIndex => prevIndex - 1);
			setDelta(period);
		}

		else if (isDeleting && updatedText === '') {
			setIsDeleting(false);
			setLoopNum(loopNum + 1);
			setIndex(1);
			setDelta(500);
		}

		else {
			setIndex(prevIndex => prevIndex + 1);
		}
	}

	return (
		<section className="banner" id="home">
			<div style={{}} className=" banner-inner-div">
				<Row className="aligh-items-center banner-row-div">
					<Col xs={12} md={6} xl={7} className="banner-left-div">
						<h1 style={{ color: "black" }}><span style={{ color: "rgb(0 54 219)" }}>Our Authentic</span> <br /> Expertise powering your digitalization journey.</h1>
						<div className="btn-home-container">
							<button className="btns" ><b ><a href="https://calendly.com/info-gmgd/30min" target="_blank">Book a free Consultation</a></b></button>
						</div>
					</Col>
					<Col xs={12} md={6} xl={5} className="banner-right-div">
						<div style={{ display: "flex", justifyContent: "center" }}>
							<div className="bannerServiceSetction">
								<div className="bannerServiceSetction-inner-div">
									<h1>100+ Salesforce CRM Implementations</h1>
									<p>Sales/Service cloud</p>
									<p>Custom Development</p>
									<p>Integrations</p>
								</div>
								<div className="bannerServiceSetction-inner-div">
									<h1>50+ Marketing Cloud Implementations</h1>
									<p>Omni-channel journeys</p>
									<p>Cross-cloud data sync</p>
									<p>Preferences Management</p>
								</div>
								<div className="bannerServiceSetction-inner-div">
									<h1>10+ Vlocity Implementations</h1>
									<p>Omni Studio</p>
									<p>Industries Packages</p>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</section>
	)
}

export default Banner;