import React, { Component } from 'react';
import './Feed.css';

class Feed extends Component {
  render() {
    return (
      <div className='right-main-div'>
       
      </div>
    );
  }
}

export default Feed;

