import React, { useState } from 'react'
import { HashLink as Link } from "react-router-hash-link";
import './css/hrmNavBar.css'
export default function HrmNavbar() {
    const [profileToggle, setProfileToggle] = useState(false)
    const handleLogoutOfhrm = () => {
        localStorage.clear();
    }

    const profileToggleFun = () => {
        setProfileToggle(!profileToggle)
    }

    return (
        <div className='hrmAdminPanelNavbar' style={{ }}>
            {/* <h1 className="headingOfhrm" style={{
                fontWeight: "lighter", color: "#1011a2",
            }}>Antrazal <span style={{ color: "#1011a2", fontWeight: 600 }}
            >work central</span></h1> */}
            <Link className="headingOfhrm" style={{
                fontWeight: 800, color: "#1011a2;", fontSize:'1.5rem'
            }} to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm">A<span style={{ color: "#1011a2;", fontWeight: 800,letterSpacing:'1px' }}
            >WC</span></Link>
            <div className='hrmNavLeftDiv' style={{gap:"1rem"}}>
                {/* <div>
                    <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm" className='hrm-button-div'>
                        <button className="hrmBtn color-forTaskBtn">Home</button>
                    </Link>
                </div>
                <div>
                    <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm/timeCard" className='hrm-button-div'>
                        <button className="hrmBtn color-forTaskBtn">Log Time</button>
                    </Link>
                </div>
                <div>
                    <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm/leaveform" className='hrm-button-div'>
                        <button className="hrmBtn color-forTaskBtn">Leaves</button>
                    </Link>
                </div>
                <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/hrmLoginPage" className='hrm-button-div'>
                    <button className="hrmBtn logout-btn-color" onClick={handleLogoutOfhrm}>Logout </button>
                </Link> */}
                <div className="centerClass">
                    <div className='searchDiv'>
                        <input
                            placeholder="search"
                            className='navBarSearch'
                        />
                    </div>

                    {/* <ul className="">
                                    
                                        <li>
                                          hello worl
                                        </li>
                                
                                </ul> */}

                </div>
                <div className='profile-div centerClass'>
                    <div className='profileButton' onClick={() => profileToggleFun()}>{localStorage.getItem('contactUserName')[0] || "N/A"}</div>
                    <div className='dropDownForProfileDiv' style={{ display: `${profileToggle ? 'block' : 'none'}` }}>
                        <div className='drop-profile-div'>
                            <p>{localStorage.getItem('contactUserName')}</p>
                        </div>
                        <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm" className='drop-profile-div' onClick={() => profileToggleFun()}>
                            <div className='drop-profile-div'>
                                Home
                            </div>
                        </Link>
                        <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm/timeCard" className='drop-profile-div' onClick={() => profileToggleFun()}>
                            <div  className='drop-profile-div' >
                                Log Time
                            </div>
                        </Link >
                        <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm/leaveform" className='drop-profile-div' onClick={() => profileToggleFun()}>
                            <div  className='drop-profile-div' >
                                Leaves
                            </div>
                        </Link>
                        <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/hrmLoginPage"  className='drop-profile-div' >
                            <div className='drop-profile-div' style={{ color: 'red' }}
                                onClick={handleLogoutOfhrm}>Logout
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
