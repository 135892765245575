import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import './css/adminPageOfHrm.css';
import './css/mobileViewMainPage.css'
import KanbanMainPage from './Kanban/KanbanMainPage'
import TaskDetailView from './TaskDetailView';
import TaskDetailViewForRight from './TaskDetailViewForRight';
import SmallLoader from './SmallLoader';
import LoaderForMainSection from './LoaderForMainSection';
import HrmNavbar from './HrmNavbar';
import KanbanForTask from './Kanban/KanbanForTask';
/*
*********************************************************************************************************
*  @Parent Component           :    App
*  @React Component Name       :    AdminPageOfHRM
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    04-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       HRM Admin panel component
********************************************************************************************************
*/

export default function AdminPageOfHRM() {
    const [searchProjectTo, setSearchProjectTo] = useState([]);
    const [searchTermStatus, setSearchTermStatus] = useState([]);
    const [selectedOptionForKanbanType, setSelectedOptionForKanbanType] = useState('All');
    const person = localStorage.getItem('contactUserName')
    const [searchAccodingTermAssignTo, setSearchAccodingTermAssignTo] = useState([{ value: localStorage.getItem('contactUserId'), label: person }]);
    const [searchTermByTaskId, setSearchTermByTaskId] = useState('')
    const [selectedId, setSelectedId] = useState(null);
    const [contact, setContact] = useState([]);
    const [editedStatus, setEditedStatus] = useState({});
    const [messageFromBackend, setMessageFromBackend] = useState('');
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('comments');
    const [projectData, setProjectData] = useState([]);
    const [allUserOption, setAllUserOption] = useState([])
    const [newFilteredData, setNewFilteredData] = useState([]);
    const [projectOption, setProjectOption] = useState([])
    const [WindowForActualHour, setWindowForActualHour] = useState(false)
    const [actualHours, setActualHours] = useState();
    const [messageType, setMessageType] = useState('');
    const [idStateClosed, setStateClosed] = useState({ id: null, index: null })
    const [taskIds, setTaskIds] = useState([]);
    const [suggestionsForTaskId, setSuggestionsForTaskId] = useState([]);
    const [toggleToKanban, setToggleToKanban] = useState(true)
    const [firstConditonOfTaskChild, setFirstConditonOfTaskChild] = useState(false)
    const [smallLoaderId, setSmallLoaderId] = useState(null);
    const [mainPageLoader, setMainPageLoader] = useState(false)
    const [loadingSectionRelatedItem, setLoadingSectionRelatedItem] = useState(false)
    const [loadingSectionComment, setLoadingSectionComment] = useState(false)
    const [loadingSectionHistory, setLoadingSectionHistory] = useState(false)
    const [relativeTask, setRelativeTask] = useState([]);
    const [projectComments, setprojectComments] = useState([]);
    const [newProjectHistory, setNewProjectHistory] = useState([]);
    const [actualFilterData, setActualFilterData] = useState([]);
    const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
    /*
    *********************************************************************************************************
    *   @React Component Name       :       AdminPageOfHRM      
    *   @Method                     :       fetchContact
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       Api call method for fetch the contact from the backend and push fetch data in state setAllUserOption in key value pairs. 
    *   @param                      :       
    *   @return                     :       
    *********************************************************************************************************
    */
    // console.log(windowWidth)
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        setWindowDimensions({ width: width, height: height });
    }
    const fetchContact = useCallback(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getContact`)
            .then((res) => res.json())
            .then((resData) => {
                setContact(resData.records);
                const userOptions = resData.records.map(contact => ({
                    label: contact.Name,
                    value: contact.Id
                }));
                setAllUserOption(userOptions)
            })
            .catch((err) => {
                setMessageWithTimeout('Contacts not fetch', 'error');
                setLoading(false);
                console.error('Error fetching contact:', err);
            });
    }, []);

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AdminPageOfHRM      
    *   @Method                     :       fetchProjectIdAndName
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       Api call method for fetch the project Name and Id from the backend and push the data lable and value pair formate in setProjectOption
    *   @param                      :       
    *   @return                     :       
    *********************************************************************************************************
    */
    const SetLoader = (val) => {
        setLoading(val)
    }
    const fetchProjectIdAndName = useCallback(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getProjectNameAndId`)
            .then((res) => res.json())
            .then((resData) => {
                setContact(resData.records);
                const userOptions = resData.records.map(project => ({
                    label: project.Title__c,
                    value: project.Id
                }));
                setProjectOption(userOptions)
            })
            .catch((err) => {
                setLoading(false);
                setMessageWithTimeout('Project Data not found ', 'error');
                console.error('Error fetching contact:', err);
            });
    }, []);

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AdminPageOfHRM      
    *   @Method                     :       fetchTaskIds
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       Api call method for fetch the Entity TaskID and Name from backend and data push in the setTaskId in labe and value pair. 
    *   @param                      :       
    *   @return                     :       
    *********************************************************************************************************
    */

    const fetchTaskIds = useCallback(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getAllTaskId`)
            .then((res) => res.json())
            .then((resData) => {
                const userOptions = resData.records.map(project => ({
                    label: project.Name,
                    value: project.Id
                }));
                setTaskIds(userOptions);
                setLoading(false);
            })
            .catch((err) => {
                setMessageWithTimeout('Project TaskId not fetch  ', 'error');
                console.error('Error fetching contact:', err);
            });
    }, []);

    /*
*********************************************************************************************************
*   @React Component Name       :       AdminPageOfHRM      
*   @Method                     :       useEffect
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       Initial call the functions fetchContact, fetchProjectIdAndName,fetchTaskIds,handleFilterChange
                   :       
*********************************************************************************************************
*/

    useEffect(() => {
        fetchContact();
        fetchProjectIdAndName();
        fetchTaskIds()
        handleFilterChange(searchTermStatus, searchAccodingTermAssignTo, searchProjectTo);
        getWindowDimensions();
    }, []);

    useEffect(() => {
        setFilterDataForNewFilterData(selectedOptionForKanbanType);
        SetActiveTabCommentOrHistory(activeTab)
        getWindowDimensions()
    }, [searchProjectTo, searchTermStatus, searchAccodingTermAssignTo, projectData, taskIds, actualFilterData, actualFilterData]);

    /*
*********************************************************************************************************
*   @React Component Name       :       AdminPageOfHRM      
*   @Method                     :       setMessageWithTimeout
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       Set the message on top of page and remove it after 3 second  
                   :       
*********************************************************************************************************
*/

    const setMessageWithTimeout = (message, typeOFmessage) => {
        setMessageFromBackend(message);
        setMessageType(typeOFmessage);
        setTimeout(() => {
            setMessageFromBackend('');
            setMessageType('');
        }, 3000);
    };

    /*
*********************************************************************************************************
*   @React Component Name       :       AdminPageOfHRM      
*   @Object                     :       statusOptions
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       object of status options in lable and value pairs 
                   :       
*********************************************************************************************************
*/

    const statusOptions = [
        { value: "Defined", label: "Defined" },
        { value: "Ready", label: "Ready" },
        { value: "In Progress", label: "In Progress" },
        { value: "Draft", label: "Draft" },
        { value: "Review", label: "Review" },
        { value: "Blocked", label: "Blocked" },
        { value: "Demoed", label: "Demoed" },
        { value: "Prod", label: "Prod" },
        { value: "Closed", label: "Closed" }
    ];

    /*
*********************************************************************************************************
*   @React Component Name       :       AdminPageOfHRM      
*   @Method                     :       handleIdClick
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       function for handel click on the any entity and show the entity details on right part of the page. 
                   :       
*********************************************************************************************************
*/

    const handleIdClick = (id) => {
        setSelectedId(id)
        SetActiveTabCommentOrHistory('comments')
        setSelectedId(id === selectedId ? '' : id);
        if (id === '') {
            return null;
        }
        else {
            fetchProjectDetail(id)
        }
    };


    /*
    *********************************************************************************************************
    *   @React Component Name       :       AdminPageOfHRM      
    *   @Method                     :       handleStatusChange
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       function for if change the status then call the api for save that in frontend and backend. Chosed status is closed the it open the window for the actual hours         
    *********************************************************************************************************
    */

    const handleStatusChange = async (id, selectedOption, index) => {
        setStateClosed({ id: id, index: index })
        setSmallLoaderId(id);
        setEditedStatus(prevState => ({ ...prevState, [id]: selectedOption.value }));
        if (selectedOption.value.toLowerCase() === 'closed') {
            setWindowForActualHour(true);
        } else {
            try {

                const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/updateProjectEntityById/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Status__c: selectedOption.value,
                        Actual_Hours__c: null,
                    }),
                });
                if (!response.ok) {
                    setSmallLoaderId(null);
                    setMessageWithTimeout('Failed to update project status', 'error');
                    throw new Error('Failed to update project status');
                }
                newFilteredData[index].Status__c = selectedOption.value

                setSmallLoaderId(null);
            } catch (error) {
                setSmallLoaderId(null);
                setMessageWithTimeout('Status not updated', 'error');
                console.error('Error:', error);
            }
        }
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AdminPageOfHRM      
    *   @Method                     :       customStyles
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       function for set priority section background color based on the current priority  
    *   @param                      :       
    *   @return                     :       
    *********************************************************************************************************
    */

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.selectProps.value && state.selectProps.value.value === 'In Progress' ? '#d9f4ff' :
                state.selectProps.value && state.selectProps.value.value === 'Blocked' ? '#ffcaca' :
                    state.selectProps.value && state.selectProps.value.value === 'Review' ? '#fef0dd' :
                        state.selectProps.value && state.selectProps.value.value === 'Closed' ? '#d5ffee' :
                            state.selectProps.value && state.selectProps.value.value === 'Defined' ? '#f2f2f2' :
                                'white',
        }),
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AdminPageOfHRM      
    *   @Method                     :       handleRefresh
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       handler the refresh button. it reset the all filter and all input fileld will blank 
    *   @param                      :       
    *   @return                     :       
    *********************************************************************************************************
    */

    const handleRefresh = () => {
        setSuggestionsForTaskId([]);
        handleFilterChange()
        setSearchTermByTaskId('')
        fetchProjectDetail(selectedId)
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AdminPageOfHRM      
    *   @Method                     :       handleResetFilters
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       handler reset filter button it set all filter initial and push the status in define or In progress and in the assignTo filter option add current user name. 
    *   @param                      :       
    *   @return                     :       
    *********************************************************************************************************
    */

    const handleResetFilters = () => {
        setSelectedId('')
        setSearchProjectTo([]);
        setSuggestionsForTaskId([]);
        setSearchTermStatus([]);
        setSearchAccodingTermAssignTo([{ value: localStorage.getItem('contactUserId'), label: person }])
        handleFilterChange(['Defined', 'In Progress'], [{ value: localStorage.getItem('contactUserId'), label: person }], [], "All")
        setSearchTermByTaskId('')
    };



    /*
*********************************************************************************************************
*   @React Component Name       :       AdminPageOfHRM      
*   @Method                     :       statusList
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       set the list of status on status search   
                   :       
*********************************************************************************************************
*/
    const statusList = [...new Set(statusOptions.map(item => item.value))];

    const setFilterDataForNewFilterData = (type) => {

        const data = actualFilterData
        if (type === "Task") {
            const filterAllTask = data.filter(task =>
                task.RecordType && task.RecordType.Name === "Task"
            );
            setNewFilteredData(filterAllTask)
        }
        else if (type === "Story") {
            const nonTaskRecords = data.filter(task =>
                !(task.RecordType && task.RecordType.Name === "Task")
            );
            setNewFilteredData(nonTaskRecords)
        }
        else {
            setNewFilteredData(data)
        }
    }

    const handleChangeForKanbanType = (event) => {
        event.preventDefault();
        setSelectedOptionForKanbanType(event.target.value);
        setFilterDataForNewFilterData(event.target.value)
    };

    /*
*********************************************************************************************************
*   @React Component Name       :       AdminPageOfHRM      
*   @Method                     :       handleFilterChange
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       handler the all filter and call api and fetch the Entities according the current filters values. 
                   :       
*********************************************************************************************************
*/

    const handleFilterChange = async (updatedStatus, updatedAssignTo, updatedProjectTo) => {
        setMainPageLoader(true);
        let status = updatedStatus ?? searchTermStatus;
        const id = (updatedAssignTo ?? searchAccodingTermAssignTo).map(option => option.value);
        const projectId = (updatedProjectTo ?? searchProjectTo).map(option => option.value);

        if (typeof status === 'function') {
            status = undefined;
        }

        const isStatusEmpty = !status || (Array.isArray(status) && status.length === 0) || (typeof status === 'string' && status.trim() === '');
        const isIdEmpty = !id || (Array.isArray(id) && id.length === 0);
        const isProjectIdEmpty = !projectId || (Array.isArray(projectId) && projectId.length === 0);
        if (isStatusEmpty && isIdEmpty && isProjectIdEmpty) {
            setNewFilteredData([])
            setActualFilterData([])
            setMainPageLoader(false);
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getFilterEntity`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    status: updatedStatus || searchTermStatus,
                    id: (updatedAssignTo || searchAccodingTermAssignTo).map(option => option.value),
                    projectId: (updatedProjectTo || searchProjectTo).map(option => option.value)
                }),
            });
            const resData = await response.json();

            setActualFilterData(resData.records)
            setFilterDataForNewFilterData(selectedOptionForKanbanType)

            setMainPageLoader(false);
        } catch (err) {
            console.error('Error fetching projects:', err);
            setMainPageLoader(false);
        }
    };

    /*
*********************************************************************************************************
*   @React Component Name       :       AdminPageOfHRM      
*   @Method                     :       handleProjectFilterOption
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       handler for change the project in project search option and call the handleFilterChange method for search the data   
                   :       
*********************************************************************************************************
*/

    const handleProjectFilterOption = (selectedOptions) => {
        setSearchProjectTo(selectedOptions);
        handleFilterChange(setSearchTermStatus, searchAccodingTermAssignTo, selectedOptions);
    };

    /*
  *********************************************************************************************************
  *   @React Component Name       :       AdminPageOfHRM      
  *   @Method                     :       handleFilterStatusChange
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       handler for change the status in status search and call the handleFilterChange accodrign to the all filter data selected option        
  *********************************************************************************************************
  */

    const handleFilterStatusChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setSearchTermStatus(selectedValues);
        handleFilterChange(selectedValues, searchAccodingTermAssignTo, searchProjectTo);
    };

    /*
  *********************************************************************************************************
  *   @React Component Name       :       AdminPageOfHRM      
  *   @Method                     :       handleFilterAssignToChange
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       handler for change the assignTo in assignTo search and call the handleFilterChange for all filter data selected option         
  *********************************************************************************************************
  */

    const handleFilterAssignToChange = (selectedOptions) => {
        setSearchAccodingTermAssignTo(selectedOptions);
        handleFilterChange(searchTermStatus, selectedOptions, searchProjectTo);
    };


    /*
  *********************************************************************************************************
  *   @React Component Name       :       AdminPageOfHRM      
  *   @Method                     :       handleTimeEstimatedHoursChange
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       handler for set state the actual hours if user want to closed the Task        
  *********************************************************************************************************
  */

    const handleTimeEstimatedHoursChange = (value) => {
        const floatValue = parseFloat(value);
        setActualHours(floatValue);
    };

    /*
  *********************************************************************************************************
  *   @React Component Name       :       AdminPageOfHRM      
  *   @Method                     :       handleSave
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       handler save if user want to choose closed task status and give the actual hours.         
  *********************************************************************************************************
  */

    const handleSave = async (e) => {
        e.preventDefault();
        setSmallLoaderId(idStateClosed.id);
        try {
            setWindowForActualHour(false);
            const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/updateProjectEntityById/${idStateClosed.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Status__c: 'Closed',
                    Actual_Hours__c: Number(actualHours),
                }),
            });

            if (!response.ok) {
                setSmallLoaderId(null);
                setMessageWithTimeout('Failed to update project status', 'error');
                throw new Error('Failed to update project status');
            }
            setSmallLoaderId(null);
            newFilteredData[idStateClosed.index].Status__c = 'Closed';

            setActualHours('');
        } catch (error) {
            setSmallLoaderId(null);
            setMessageWithTimeout('Status not updated', 'error');
            console.error('Error:', error);
        }
    };

    /*
  *********************************************************************************************************
  *   @React Component Name       :       AdminPageOfHRM      
  *   @Method                     :       isPastDue
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       check the current entity is not completed and its status is not closed then change the color of that entity is red        
  *********************************************************************************************************
  */

    function isPastDue(item) {
        if (selectedId === item.Id) return false
        if (!item.Due_Date__c || item.Status__c === 'Closed') return false;
        const now = new Date();
        const due = new Date(item.Due_Date__c);
        return due < now.setHours(0, 0, 0, 0) || due.getDate() === now.getDate();
    }

    /*
  *********************************************************************************************************
  *   @React Component Name       :       AdminPageOfHRM      
  *   @Method                     :       handlWindowForActualHour
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       open window if user chose  the status is closed then open the window         
  *********************************************************************************************************
  */

    const handlWindowForActualHour = () => {
        setWindowForActualHour(!WindowForActualHour)
        setSmallLoaderId(null);
    }

    /*
  *********************************************************************************************************
  *   @React Component Name       :       AdminPageOfHRM      
  *   @Method                     :       handleInputChange
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       task id search input hndler or filter the task Id onChnge in the input section        
  *********************************************************************************************************
  */

    const handleInputChange = (e) => {
        const searchValue = e.target.value;
        setSearchTermByTaskId(searchValue);
        if (searchValue.trim()) {
            const filteredSuggestions = taskIds.filter(task =>
                task.label.toLowerCase().includes(searchValue.toLowerCase())
            );
            setSuggestionsForTaskId(filteredSuggestions);
        } else {
            setSuggestionsForTaskId([]);
        }
    };

    /*
*********************************************************************************************************
*   @React Component Name       :       AdminPageOfHRM      
*   @Method                     :       handleSuggestionClick
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       handler for click on the suggition on option Task id and call tha api and Put set the Entity data in ProjectData and show entity details on right section          
*********************************************************************************************************
*/

    const handleSuggestionClick = (suggestion) => {
        handleIdClick(suggestion.value)
        setSearchTermByTaskId(suggestion.label);
        const searchValue = suggestion.label.trim();
        if (!searchValue) {
            alert("Please enter a search term.");
            return;
        }
        setLoading(true);
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntityDetailsTaskId/${searchValue}`)
            .then((res) => res.json())
            .then((resData) => {
                setNewFilteredData(resData.records)
                setLoading(false);
                setSearchTermByTaskId('')
            })
            .catch((err) => {
                setLoading(false);
                setMessageWithTimeout(`faild to  Search Task Id ${searchValue} Data `, 'error');
                setSearchTermByTaskId()
            });
        setSuggestionsForTaskId([]);
    };

    const toggleKanban = () => {
        setToggleToKanban(!toggleToKanban)
        setSelectedId('')
    }

    const handelClosedBtnKanbanDetails = () => {
        setSelectedId('')
    }

    const fetchProjectRelativeTask = useCallback(async (id) => {
        if (!id) {
            return;
        }
        setLoadingSectionRelatedItem(true);
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntityrelativeTask/${id}`);
            const resData = await res.json();
            setRelativeTask(resData.records);
            if (resData.records[0].Parent_Entity__r) {

            }
        } catch (err) {
            setMessageWithTimeout('Failed to fetch project relative tasks', 'error');
            console.error('Error fetching relative tasks:', err);
        } finally {
            setLoadingSectionRelatedItem(false);
        }
    }, []);

    const fetchProjectComments = useCallback(async (id) => {
        if (!id) {
            return;
        }
        setLoadingSectionComment(true);

        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntitycomments/${id}`);
            const resData = await res.json();
            setprojectComments(resData.records);
        } catch (err) {
            setLoadingSectionComment(false);
            setMessageWithTimeout('Failed to fetch project comments', 'error');
            console.error('Error fetching comments:', err);
        } finally {

            setLoadingSectionComment(false);
        }
    }, []);

    const fetchProjectHistory = useCallback(async (id) => {
        if (!id) {
            return;
        }
        setLoadingSectionHistory(true);
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntityHistoryByTaskId/${id}`);
            const resData = await res.json();
            setNewProjectHistory(resData.records);
        } catch (err) {
            setLoadingSectionHistory(false);
            setMessageWithTimeout('Failed to fetch Task History', 'error');
            console.error('Error fetching history:', err);
        } finally {
            setLoadingSectionHistory(false);

        }
    }, []);

    const fetchProjectDetail = useCallback(async (id) => {
        if (!id) {
            return;
        } else {


            setLoading(true);
            try {
                const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/EntityDetails/${id}`);
                const resData = await res.json();
                // console.log("projectData", projectData)
                setProjectData(resData.records);
                setLoading(false);
                const project = resData.records[0]
                if (
                    ['Development', 'Solution Design', 'Unit Testing', 'Review', 'Deployment'].some(prefix => project.Title__c.startsWith(prefix)) &&
                    project.RecordType.Name === 'Task' && project.Parent_Entity__r.RecordType.Name === "User Story"
                ) {
                    setFirstConditonOfTaskChild(true);
                    SetActiveTabCommentOrHistory('history')
                }
                else {
                    setFirstConditonOfTaskChild(false);
                }
                await fetchProjectRelativeTask(id);
                await fetchProjectComments(id);
                await fetchProjectHistory(id);
            } catch (err) {
                setLoading(false);
                setMessageWithTimeout('Failed to fetch project data', 'error');
                console.error('Error fetching project details:', err);
            } finally {
                setLoading(false);
            }
        }
    }, [fetchProjectRelativeTask, fetchProjectComments, fetchProjectHistory]);

    const updateProjectComments = (newComment) => {
        setprojectComments(prevProjectData => {
            const newRecord = {
                "Id": newComment.Id,
                "Comment__c": newComment.content,
                "CreatedDate": new Date().toISOString(),
                "Commented_By__r": {
                    "Name": localStorage.getItem('contactUserName')
                }
            };

            if (!prevProjectData || !prevProjectData.length || !prevProjectData[0].Project_Entity_Commnets__r) {
                const updatedProject = {
                    ...prevProjectData[0],
                    Project_Entity_Commnets__r: {
                        records: [newRecord]
                    }
                };
                return [updatedProject];
            }

            const existingRecords = [...prevProjectData[0].Project_Entity_Commnets__r.records];
            existingRecords.unshift(newRecord);

            const updatedProject = {
                ...prevProjectData[0],
                Project_Entity_Commnets__r: {
                    ...prevProjectData[0].Project_Entity_Commnets__r,
                    records: existingRecords
                }
            };

            return [updatedProject];
        });
    };
    const SetActiveTabCommentOrHistory = (tab) => {
        setActiveTab(tab)
    }
    // console.log("windowDimensions", windowDimensions)
    function getInitials(name) {
        const initials = name.split(' ').map(word => word[0]).join('');
        return initials.toUpperCase().slice(0, 2);
    }
    const openNewTabWindow = (id) => {
        const url = `/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm/task-detail/?id=${id}`;
        window.open(url, "_self");
    }
    return (

        <div className='adminPanelMrm' style={{ color: "black" }}>
            {WindowForActualHour && (
                <form className="centered-window" onSubmit={handleSave}>
                    <div className='actualhourWindowCloseDiv' onClick={handlWindowForActualHour}>
                        <svg viewBox="0 0 24 24" fill="none" ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Close_MD"> <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
                    </div>
                    <div className="formForWindoDiv">
                        <label htmlFor="">Enter Actual Hours</label>
                        <input
                            type="number"
                            min="0"
                            placeholder="Enter actual hours"
                            className="timeCardTimeinput"
                            value={actualHours}
                            onChange={(e) => handleTimeEstimatedHoursChange(e.target.value)}
                            required
                            step="any"
                            id="hrmmytimeInput"
                        />
                        <button type="submit">Save</button>
                    </div>
                </form>
            )}

            <HrmNavbar />
            <>
                <div className='adminPanelMrmHeading-div'>
                    {messageFromBackend &&
                        <div className="messageofHrm">
                            <div className={`${messageType}`}>
                                {messageFromBackend}
                            </div>
                        </div>
                    }

                    <div className='adminPanelMrmHeading-inner-div'>
                        <div className="input-hrm_div">
                            <Select
                                isMulti
                                value={searchProjectTo}
                                onChange={handleProjectFilterOption}
                                options={projectOption}
                                placeholder="Project Name"
                            />
                        </div>
                        <div className="input-hrm_div">
                            <Select
                                isMulti
                                value={searchTermStatus.map(status => ({ value: status, label: status }))}
                                onChange={handleFilterStatusChange}
                                options={statusList.map(status => ({ value: status, label: status }))}
                                placeholder="Status"
                            />
                        </div>
                        <div className="input-hrm_div">
                            <Select
                                isMulti
                                value={searchAccodingTermAssignTo.map(user => ({ value: user.value, label: user.label }))}
                                onChange={handleFilterAssignToChange}
                                options={allUserOption}
                                placeholder="Assign To"
                            />
                        </div>

                        <div className="input-hrm_div search-div">
                            <div className='searchDiv'>
                                <input
                                    placeholder="Type to search..."
                                    value={searchTermByTaskId}
                                    onChange={handleInputChange}
                                />
                            </div>
                            {suggestionsForTaskId.length > 0 && (
                                <ul className="suggestions-list">
                                    {suggestionsForTaskId.map((suggestion) => (
                                        <li key={suggestion.value} onClick={() => handleSuggestionClick(suggestion)}>
                                            {suggestion.label}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className='input-hrm_div' style={{ display: "flex", gap: "1rem", cursor: 'pointer' }}>

                            <div className="ref svg-outer-div assignToLogoStyleNew" onClick={handleRefresh}>
                                <svg viewBox="0 0 24 24" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0" ></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 12C3 16.9706 7.02944 21 12 21C14.3051 21 16.4077 20.1334 18 18.7083L21 16M21 12C21 7.02944 16.9706 3 12 3C9.69494 3 7.59227 3.86656 6 5.29168L3 8M21 21V16M21 16H16M3 3V8M3 8H8" stroke="rgb(16, 17, 162)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                <div className='tooltipNewLeftNewRight'>Refresh Button</div>
                            </div>
                            <div className="ref svg-outer-div assignToLogoStyleNew" onClick={handleResetFilters} style={{ cursor: 'pointer' }}>
                                <svg
                                    fill="rgb(16, 17, 162)"
                                    height="117px"
                                    width="117px"
                                    version="1.1"
                                    id="Capa_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 206.559 206.559"
                                    xmlSpace="preserve"
                                >
                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path d="M0,103.279c0,56.948,46.331,103.279,103.279,103.279s103.279-46.331,103.279-103.279S160.228,0,103.279,0 S0,46.331,0,103.279z M170,103.279c0,36.79-29.931,66.721-66.721,66.721c-11.765,0-22.821-3.072-32.429-8.439L161.56,70.85 C166.927,80.458,170,91.514,170,103.279z M103.279,36.559c11.765,0,22.821,3.072,32.429,8.439l-90.709,90.711 c-5.368-9.608-8.44-20.664-8.44-32.43C36.559,66.489,66.489,36.559,103.279,36.559z"></path>
                                    </g>
                                </svg>
                                <div className='tooltipNewLeftNewRight'>Reset filter Button</div>
                            </div>
{  windowDimensions.width < 760 ? (<></>
                            ):(<div className="ref assignToLogoStyleNew" style={{ display: "flex", flexDirection: "column" }}>
                                <label className="switch" style={{ margin: '0' }}>
                                    <input type="checkbox" checked={toggleToKanban} onChange={toggleKanban} />
                                    <span className="slider"></span>
                                </label>
                                <span className="toggle-label">Kanban </span>
                                <div className='tooltipNewLeftNewRight'>Kanban Toggle</div>
                            </div>)
                            }
                            <div>
                                <select
                                    id="task-filter"
                                    value={selectedOptionForKanbanType}
                                    onChange={handleChangeForKanbanType}
                                    style={{ border: " 1px solid #ccc" }}
                                >
                                    <option value="All">All</option>
                                    <option value="Task">Task</option>
                                    <option value="Story">Story</option>
                                </select>

                            </div>
                        </div>
                    </div>
                </div>

                {windowDimensions.width < 760 ? (<div className='container'>

                    {newFilteredData && newFilteredData.map((item, index) => (
                        <div
                            key={item.Id}
                            className={`${selectedId === item.Id ? 'selected ' : ''}${isPastDue(item) ? 'pastDue' : ''}`}
                            style={{ width: "100%", backgroundColor: isPastDue(item) ? '#ffcccc' : '', padding: '0.5rem', marginBottom: '0.5rem', border: '1px solid #ccc',borderRadius:'0.5rem' }}
                            onClick={() => openNewTabWindow(item.Id)}
                        >
                            <h1 className='mobile-card-Heading colorClassHeading'>{item.
                                Title__c ? item.
                                Title__c : ''}</h1>
                            <div className='heading-id-div'>
                                <p>{item.Name ? item.Name : ''}</p>
                                <p style={{
                                    padding: "0.1rem 1rem",
                                    borderRadius: "0.4rem",
                                    fontWeight: "bold",
                                    width: "fit-content",
                                    textAlign: "center",
                                    fontSize: "0.6rem",
                                    color: "#fff",
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: item.Priority__c === 'High' ? '#ff0000' :
                                        item.Priority__c === 'Medium' ? '#ffbf00' :
                                            item.Priority__c === 'Low' ? '#00c853' :
                                                'red'
                                }}>
                                    {item.Priority__c ? item.Priority__c : ""}
                                </p>
                            </div>
                            <div className="mobilesubDetail">
                                <p>
                                    {item.Status__c ? item.Status__c || '' : ''}
                                </p>

                                <p>{item.Due_Date__c ? item.Due_Date__c : ''}</p>


                                <div className='mobileassignToLogoStyle'>
                                    {getInitials(item.Assigned_To__r && item.Assigned_To__r.Name ? item.Assigned_To__r.Name : '')}
                                </div>

                            </div>
                        </div>
                    ))}



                </div>) : (<>{toggleToKanban ?
                    <>
                        {
                            toggleToKanban && selectedId ? (
                                <TaskDetailView
                                    selectedId={selectedId}
                                    projectData={projectData}
                                    allUserOption={allUserOption}
                                    handelClosedBtnKanbanDetails={handelClosedBtnKanbanDetails}
                                    updateProjectData={updateProjectComments}
                                    loading={loading} SetLoader={SetLoader}
                                    setMessageWithTimeout={setMessageWithTimeout}
                                    handleIdClick={handleIdClick}
                                    relativeTask={relativeTask}
                                    newProjectHistory={newProjectHistory}
                                    projectComments={projectComments}
                                    loadingSectionRelatedItem={loadingSectionRelatedItem}
                                    loadingSectionComment={loadingSectionComment}
                                    loadingSectionHistory={loadingSectionHistory}
                                    SetActiveTabCommentOrHistory={SetActiveTabCommentOrHistory}
                                    activeTab={activeTab}
                                    firstConditonOfTaskChild={firstConditonOfTaskChild}
                                />
                            ) : null
                        }
                        {
                            newFilteredData.length == 0 ? (
                                <h1 style={{ textAlign: 'center', fontSize: '1.4rem' }}>Please apply filters to see your assigned entities.</h1>
                            ) :

                                (

                                    selectedOptionForKanbanType === "Task" ? (
                                        <div><KanbanForTask newFilteredData={newFilteredData} handleIdClick={handleIdClick} mainPageLoader={mainPageLoader} setMessageWithTimeout={setMessageWithTimeout} /></div>
                                    ) : (


                                        <>

                                            <KanbanMainPage newFilteredData={newFilteredData} handleIdClick={handleIdClick} mainPageLoader={mainPageLoader} setMessageWithTimeout={setMessageWithTimeout} />

                                        </>
                                    )
                                )
                        }
                    </>
                    :
                    <div className='adminPanelMrm-main-div'>
                        {selectedId ? (
                            <>
                                {
                                    newFilteredData.length == 0 ? (
                                        <h1 className='adminPanelMrmTable selected-id' style={{ width: "100%", textAlign: 'center', fontSize: '1.4rem' }}>Please apply filters to see your assigned entities.</h1>
                                    ) :

                                        (
                                            <div className='adminPanelMrmTable selected-id' style={{ width: "100%" }}>

                                                <table style={{ width: "100%", fontSize: "0.8rem", position: 'relative' }}>
                                                    <LoaderForMainSection loading={mainPageLoader} />
                                                    <thead className='table-header-fix'>
                                                        <tr>
                                                            <th>Task Id</th>
                                                            <th>Project Name</th>
                                                            <th>Title</th>
                                                            <th>Type</th>
                                                            <th>Status</th>
                                                            <th>Priority</th>
                                                            <th>Due Date</th>
                                                            <th>Closed Date</th>
                                                            <th>Assigned To</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {newFilteredData && newFilteredData.map((item, index) => (
                                                            <tr
                                                                key={item.Id}
                                                                className={`
                                              ${selectedId === item.Id ? 'selected ' : ''}
                                              adminPanelMrmTable
                                              ${isPastDue(item) ? 'pastDue' : ''}
                                            `}
                                                                style={{ width: "100%", backgroundColor: isPastDue(item) ? '#ffcccc' : '' }} >
                                                                <td onClick={() => handleIdClick(item.Id)} style={{ cursor: "pointer" }}>{item.Name ? item.Name : ''}</td>
                                                                <td onClick={() => handleIdClick(item.Id)} style={{ cursor: "pointer" }}>{item.Project__r.Title__c ? item.Project__r.Title__c : ''}</td>
                                                                <td onClick={() => handleIdClick(item.Id)} style={{ cursor: "pointer" }}>{item.
                                                                    Title__c ? item.
                                                                    Title__c : ''}</td>
                                                                <td>{item.RecordType ? item.RecordType.Name : ""}</td>
                                                                <td>
                                                                    {
                                                                        smallLoaderId === item.Id ? (
                                                                            <SmallLoader />
                                                                        ) : (<Select
                                                                            value={statusOptions.find(option => option.value === (editedStatus[item.Id] || item.Status__c ? item.Status__c : ''
                                                                            ))}
                                                                            onChange={(selectedOption) => { handleStatusChange(item.Id, selectedOption, index) }}
                                                                            options={statusOptions}
                                                                            defaultValue={item.Status__c ? item.Status__c || '' : ''}
                                                                            styles={customStyles}
                                                                            style={{ color: "black !important" }}
                                                                        />)

                                                                    }
                                                                </td>
                                                                <td style={{
                                                                    backgroundColor: item.Priority__c === 'High' ? '#fef0dd' :
                                                                        item.Prority__c === 'Medium' ? '#d1eaff' :
                                                                            item.Prority__c === 'Critical' ? '#ffcaca' :
                                                                                item.Prority__c === 'Low' ? '#d5ffee' :
                                                                                    '#fff', color: 'black'
                                                                }}>
                                                                    {item.Priority__c ? item.Priority__c : ""}
                                                                </td>
                                                                <td>{item.Due_Date__c ? item.Due_Date__c : ''}</td>
                                                                <td>{item.Closed_Date__c ? item.Closed_Date__c : ''}</td>
                                                                <td>{item.Assigned_To__r && item.Assigned_To__r.Name ? item.Assigned_To__r.Name : ''}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                }
                                {

                                    <TaskDetailViewForRight
                                        selectedId={selectedId}
                                        projectData={projectData}
                                        allUserOption={allUserOption}
                                        handelClosedBtnKanbanDetails={handelClosedBtnKanbanDetails}
                                        updateProjectData={updateProjectComments}
                                        loading={loading} SetLoader={SetLoader}
                                        setMessageWithTimeout={setMessageWithTimeout}
                                        relativeTask={relativeTask}
                                        newProjectHistory={newProjectHistory}
                                        projectComments={projectComments}
                                        loadingSectionRelatedItem={loadingSectionRelatedItem}
                                        loadingSectionComment={loadingSectionComment}
                                        loadingSectionHistory={loadingSectionHistory}
                                        SetActiveTabCommentOrHistory={SetActiveTabCommentOrHistory}
                                        activeTab={activeTab}
                                        firstConditonOfTaskChild={firstConditonOfTaskChild}
                                    />
                                }
                            </>
                        ) : (
                            <>
                                {
                                    newFilteredData.length == 0 ? (
                                        <h1 className='adminPanelMrmTable selected-id' style={{ width: "100%", textAlign: 'center', fontSize: '1.4rem' }}>Please apply filters to see your assigned entities. </h1>
                                    ) :

                                        (
                                            <div className='adminPanelMrmTable' style={{ width: "100%" }}>
                                                <table style={{ width: "100%", fontSize: "0.8rem", position: 'relative' }}>
                                                    <LoaderForMainSection loading={mainPageLoader} />
                                                    <thead className='table-header-fix'>
                                                        <tr>
                                                            <th>Task Id</th>
                                                            <th style={{ width: '16%' }}>Project Name</th>
                                                            <th style={{ width: '22%' }}>Title</th>
                                                            <th>Type</th>
                                                            <th>Status</th>
                                                            <th>Priority</th>
                                                            <th>Due Date</th>
                                                            <th>Closed Date</th>
                                                            <th>Assigned To</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ fontSize: "0.8rem" }}>
                                                        {newFilteredData && newFilteredData.map((item, index) => (
                                                            <tr key={item.Id}
                                                                className={`
                                                                            ${selectedId === item.Id ? 'selected ' : ''}
                                                                            adminPanelMrmTable
                                                                            ${isPastDue(item) ? 'pastDue' : ''}
                                                                        `}
                                                                style={{ width: "100%", backgroundColor: isPastDue(item) ? '#ffcccc' : '' }}>
                                                                <td onClick={() => handleIdClick(item.Id)} style={{ cursor: "pointer" }}>{item.Name ? item.Name : ''}</td>
                                                                <td onClick={() => handleIdClick(item.Id)} style={{ cursor: "pointer" }}>{item.Project__r.Title__c ? item.Project__r.Title__c : ''}</td>
                                                                <td onClick={() => handleIdClick(item.Id)} style={{ cursor: "pointer" }}>{item.
                                                                    Title__c ? item.
                                                                    Title__c : ''}</td>
                                                                <td>{item.RecordType ? item.RecordType.Name : ""}</td>
                                                                <td>
                                                                    {
                                                                        smallLoaderId === item.Id ? (
                                                                            <SmallLoader />
                                                                        ) : (
                                                                            <Select
                                                                                value={statusOptions.find(option => option.value === (editedStatus[item.Id] || item.Status__c ? item.Status__c : ''
                                                                                ))}
                                                                                onChange={(selectedOption) => handleStatusChange(item.Id, selectedOption, index)}
                                                                                options={statusOptions}
                                                                                defaultValue={item.Status__c}
                                                                                styles={customStyles}
                                                                                style={{ color: "black !important" }}
                                                                            />
                                                                        )

                                                                    }
                                                                </td>
                                                                <td style={{
                                                                    backgroundColor: item.Priority__c === 'High' ? '#fef0dd' :
                                                                        item.Priority__c === 'Medium' ? '#d1eaff' :
                                                                            item.Priority__c === 'Critical' ? '#ffcaca' :
                                                                                item.Priority__c === 'Low' ? '#d5ffee' :
                                                                                    '#fff', color: 'black'
                                                                }}>
                                                                    {item.
                                                                        Priority__c ? item.
                                                                        Priority__c : ""}
                                                                </td>
                                                                <td>{item.Due_Date__c ? item.Due_Date__c : ''}</td>
                                                                <td>{item.Close_Date__c ? item.Close_Date__c : ''}</td>
                                                                <td>{item.Assigned_To__r && item.Assigned_To__r.Name ? item.Assigned_To__r.Name : ''}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                }
                            </>

                        )}
                    </div>}</>)
                }
            </>
        </div >
    );
}

