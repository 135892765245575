
import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";

/*
*********************************************************************************************************
*  @Parent Component           :    
*  @React Component Name       :    CarouselLogo
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Progressive add products LWC component development
********************************************************************************************************
*/

function CarouselLogo() {

  const [posts, setPosts] = useState([]);

	/*
	*********************************************************************************************************
	*   @React Component Name       :       testimonial
	*   @Method                     :       fetchPosts
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       fetch the service data from the server 
	*********************************************************************************************************
	*/

  const fetchPosts = () => {
    fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/testimonial`)
      .then((res) => res.json())
      .then((resData) => {
        setPosts(resData);
      })
      .catch((err) => {
        console.error('Error fetching blog posts:', err);
      });
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <AppContainer>
      <Wrapper>
        <Marquee>
          <MarqueeGroup>
            {posts.map((currentElement) => {
              const { id, organisation_logo } = currentElement;
              return (
                <ImageGroup>
                  <Image src={organisation_logo} />
                </ImageGroup>
              );
            })}
          </MarqueeGroup>
          <MarqueeGroup>
            {posts.map((currentElement) => {
              const { id, organisation_logo } = currentElement;
              return (
                <ImageGroup>
                  <Image src={organisation_logo} />
                </ImageGroup>
              );
            })}
          </MarqueeGroup>
        </Marquee>
      </Wrapper>
    </AppContainer>
  );
}

export default CarouselLogo;

const AppContainer = styled.div`
  color: #000000;
  height:25vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
overflow: hidden;
  width: 100%;
  height: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Text = styled.div`
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #02203c;
`;

const Note = styled.div`
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 40px;
  color: #7c8e9a;
`;

const Marquee = styled.div`
  display: flex;
  width: 1200px;
  overflow: hidden;
  user-select: none;
`;

const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  animation: ${scrollX} 30s linear infinite;
`;

const MarqueeGroup = styled.div`
  ${common}
`;

const MarqueeGroup2 = styled.div`
  ${common}
  animation-direction: reverse;
  animation-delay: -3s;
`;

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
    width: clamp(10rem, 1rem + 40vmin, 20rem);
    padding: calc(clamp(10rem, 1rem + 18vmin, 19rem) / 10);
`;

const Image = styled.img`
  object-fit: contain;
  width: 75%;
  height: 100%;
  border-radius: 0.5rem;
  aspect-ratio: 16/9;
`;