import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap'
import "../Components/css/Testimonial.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
/*
*********************************************************************************************************
*  @Parent Component           :    Main Page 
*  @React Component Name       :    Testimonial
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Testimonial section 
********************************************************************************************************
*/
const Testimonial = () => {

	const [posts, setPosts] = useState([]);
	const [firstpost, setfirstpost] = useState([]);

/*
*********************************************************************************************************
*   @React Component Name       :       Testimonial
*   @Method                     :       fetchPosts
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       fetch the Testimonial data for render 
*********************************************************************************************************
*/

	const fetchPosts = () => {
		fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/testimonial`)
			.then((res) => res.json())
			.then((resData) => {
				setPosts(resData);
				if (resData.length === 0) {
					setfirstpost([])
				}
				else {
					setfirstpost(resData[0])

				}
			})
			.catch((err) => {
				console.error('Error fetching blog posts:', err);

			});
	};

/*
*********************************************************************************************************
*   @React Component Name       :       Testimonial
*   @Method                     :       useEffect
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       call the api 
*********************************************************************************************************
*/

	useEffect(() => {
		fetchPosts();
	}, []);

	return (
		<>
			<section style={{ "background": "" }} >
				<Container style={{ paddingTop: "2rem" }}>
					<p className="heading" style={{ "textAlign": "center", "color": "#4041b5" }}>
						SUCCESS STORIES
					</p>
					<div id='testimonial' className="carousel slide" data-ride="carousel">
						<div className="carousel-inner">
							<div className="carousel-item active">
								<div className="carousel-caption">
									<p>{firstpost.content || null} </p>
									<img src={firstpost.photo} />
									<div id="image-caption" style={{ fontSize: "1.25rem" }}><b>{firstpost.client_name}</b></div>
									<div id="image-caption" style={{ fontSize: "0.95rem", marginTop: "-7px", color: 'black' }}><b>{firstpost.designation}, </b><span>{firstpost.organisation}</span></div>
								</div>
							</div>
							{posts.map((currentElement) => {
								const { id, client_name, designation, organisation, photo, organisation_logo, content } = currentElement;
								return (
									<>
										{content && photo && client_name && designation && organisation && (
											<div className="carousel-item" key={id}>
												<div className="carousel-caption">
													<p>{content}</p>
													{photo && <img src={photo} />}
													<div id="image-caption" style={{ fontSize: "1.25rem" }}>
														<b>{client_name}</b>
													</div>
													<div id="image-caption" style={{ fontSize: "0.95rem", marginTop: "-7px", color: 'black' }}>
														<b>{designation},</b> <span style={{ color: 'black' }}>{organisation}</span>
													</div>
												</div>
											</div>
										)}
									</>
								);
							})}
						</div>
						<a className="carousel-control-prev " href="#testimonial" data-slide="prev">
							<i className='fas fa-arrow-left butn'></i>
						</a> <a className="carousel-control-next" href="#testimonial" data-slide="next">
							<i className='fas fa-arrow-right butn'></i>
						</a>

					</div>
				</Container>
			</section>
		</>
	)
}

export default Testimonial;