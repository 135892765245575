import React from "react";
import styled from "styled-components";
import Card from "./Card";
import { Droppable } from "react-beautiful-dnd";

const Container = styled.div`
    background-color: ${(props) => (props.isDraggingOver ? '#e9f2ff' : '#f7f8f9')};
    border-radius: 2.5px;
    width: 400px;
    height: 900px;
    margin: 0 2px;
    overflow-y: scroll;
    // -ms-overflow-style: none;  /* Hide scrollbars in IE and Edge */
    // scrollbar-width: none;  /* Hide scrollbars in Firefox */
    border: 1px solid gray;

    &::-webkit-scrollbar {
        width: 0.1rem; /* Set custom width */
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Background of the scrollbar track */
        border-radius: 2.5px; /* Optional: rounding the corners */
    }

    &::-webkit-scrollbar-thumb {
        background: #888; /* Scrollbar thumb color */
        border-radius: 0.2rem; /* Optional: rounding the corners */
    }

    /* Optional: Change thumb color on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Darker color when hovering */
    }
`;

const Title = styled.h3`
    padding: 8px;
    font-size: 1rem;
    background-color: rgb(16, 17, 162);
    text-align: center;
    position: sticky;
    color:white;
    top: 0;
    z-index: 9;
    font-size:0.9rem;
`;

const TaskList = styled.div`
    padding: 0px;
    transition: background-color 0.2s ease;
    flex-grow: 1;
    min-height: 100px;
`;

export default function Column({ title, tasks, id,handleIdClick,taskLoading,count}) {
    // console.log("task",tasks)
    return (
        <Droppable droppableId={id}>
            {(provided, snapshot) => (
                <Container
                    isDraggingOver={snapshot.isDraggingOver}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    
                >
                    <Title>{title ? title :null}&nbsp;({count})</Title>
                    <TaskList>
                        {tasks ? tasks.map((task, index) => (
                            <Card index={index} task={task} handleIdClick={handleIdClick} taskLoading={taskLoading}/>
                        )):null}
                        {provided.placeholder}
                    </TaskList>
                </Container>
            )}
        </Droppable>
    );
}
