import React, { useState } from 'react';
import styled from 'styled-components';

const DatePickerWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 230px; /* Adjust width as needed */
  z-index: 9999;
`;

const Calendar = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 280px; /* Set a fixed width for the calendar */
`;

const Day = styled.div`
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: #f0f0f0;
  }
`;

const Week = styled.div`
  display: flex;
`;

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #f0f0f0;
  border-bottom: 1px solid #ccc;
`;

const CalendarBody = styled.div`
  padding: 10px;
`;

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
`;

/*
*********************************************************************************************************
*   @React Component Name       :       DateCalender
*   @Method                     :       formatDate
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       it formate the current date in yy-mm-dd
*********************************************************************************************************
*/

const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${year}-${month}-${day}`;
};

/*
*********************************************************************************************************
*  @Parent Component           :    TimeCard
*  @React Component Name       :    DateCalender
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    04-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Date picker and show today date 
********************************************************************************************************
*/

const DateCalender = (props) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());

  /*
*********************************************************************************************************
*   @React Component Name       :       toggleCalendar
*   @Method                     :       formatDate
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       toggle the calende or hyandle onclick on the calander
*********************************************************************************************************
*/
  const toggleCalendar = () => setShowCalendar(!showCalendar);
  /*
  *********************************************************************************************************
  *   @React Component Name       :       DateCalender
  *   @Method                     :       handleDateClick
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       set the current date in the setShowCalendar and setSelectedDate
  *********************************************************************************************************
  */

  const handleDateClick = (date) => {
    props.timeDate(date);
    setSelectedDate(date);
    setShowCalendar(false);
  };

  /*
  *********************************************************************************************************
  *   @React Component Name       :       DateCalender
  *   @Method                     :       renderCalendar
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       It is render entair calender accoding to the month and year 
  *********************************************************************************************************
  */

  const renderCalendar = () => {
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const daysInMonth = [];
    const startDay = startOfMonth.getDay();
    for (let i = 0; i < startDay; i++) {
      daysInMonth.push(null);
    }
    for (let i = startOfMonth.getDate(); i <= endOfMonth.getDate(); i++) {
      daysInMonth.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), i));
    }
    const weeks = [];
    let week = [];
    daysInMonth.forEach((date) => {
      week.push(date);
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
    });
    if (week.length > 0) {
      weeks.push(week);
    }

    /*
    *********************************************************************************************************
    *   @React Component Name       :       DateCalender
    *   @Method                     :       formatDate
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       render entair calender UI
    *********************************************************************************************************
    */

    return (
      <Calendar style={{ zIndex: '9999' }}>
        <CalendarHeader>
          <Button onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1))}>
            {'<'}
          </Button>
          <span>
            {currentDate.getMonth() + 1}/{currentDate.getFullYear()}
          </span>
          <Button onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1))}>
            {'>'}
          </Button>
        </CalendarHeader>
        <CalendarBody>
          <Week>
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
              <Day key={day}>{day}</Day>
            ))}
          </Week>
          {weeks.map((week, index) => (
            <Week key={index}>
              {week.map((date, i) => (
                <Day
                  key={i}
                  onClick={() => date && handleDateClick(date)}
                  style={{
                    backgroundColor: date && date.toDateString() === selectedDate.toDateString() ? '#f0f0f0' : 'transparent',
                  }}
                >
                  {date ? date.getDate() : ''}
                </Day>
              ))}
            </Week>
          ))}
        </CalendarBody>
      </Calendar>
    );
  };

  return (
    <DatePickerWrapper>
      <Input
        type="text"
        onFocus={toggleCalendar}
        value={selectedDate ? formatDate(selectedDate) : ''}
        readOnly
      />
      {showCalendar && renderCalendar()}
    </DatePickerWrapper>
  );
};

export default DateCalender;
