import React, { Component } from 'react';
import Input from '../../components/Form/Input/Input';
import Button from '../../components/Button/Button';
import Auth from '../Auth/Auth';
import Multiselect from 'multiselect-react-dropdown';
import Post from '../../components/Feed/Post/Post';
import Loader from '../../components/Loader/Loader';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ImageUrlGenerator from './GetPublicUrlOfImages';

/*
*********************************************************************************************************
*  @Parent Component           :    App Admin Panel 
*  @React Component Name       :    AreaOfExpertise
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    04-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       AreaOfExpertise admin panel 
********************************************************************************************************
*/

class AreaOfExpertise extends Component {

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AreaOfExpertise
    *   @Method                     :       constructor
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       initialized input variables 
    *********************************************************************************************************
    */

    constructor(props) {
        super(props);
        this.state = {
            submitBtn: false,
            areaOfExpertiseForm: {
                name: {
                    value: '',
                    valid: false,
                    validators: [],
                },
            },
            formShow: false,
            services: [],
            selectedServices: [],
            posts: [],
            postsLoading: false,
            editPostBtn: false,
            editPostId: null,
        };
    }

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AreaOfExpertise
    *   @Method                     :       inputChangeHandler
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       it handles the input changes 
    *********************************************************************************************************
    */

    inputChangeHandler = (input, value) => {
        this.setState((prevState) => {
            let isValid = true;
            for (const validator of prevState.areaOfExpertiseForm[input].validators) {
                isValid = isValid && validator(value);
            }
            const updatedForm = {
                ...prevState.areaOfExpertiseForm,
                [input]: {
                    ...prevState.areaOfExpertiseForm[input],
                    valid: isValid,
                    value: value,
                },
            };
            let formIsValid = true;
            for (const inputName in updatedForm) {
                formIsValid = formIsValid && updatedForm[inputName].valid;
            }
            return {
                areaOfExpertiseForm: updatedForm,
                formIsValid: formIsValid,
            };
        });
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AreaOfExpertise
    *   @Method                     :       toggleForm
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       toggle the form for the creating or updating mode
    *********************************************************************************************************
    */
    toggleForm = () => {
        this.setState((prevState) => ({
            formShow: !prevState.formShow,
        }));

    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AreaOfExpertise
    *   @Method                     :       getServiceData
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       Get all services Data from the backend.
    *********************************************************************************************************
    */

    getServiceData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/services`);
            if (!response.ok) {
                throw new Error('Failed to fetch Service data');
            }
            const data = await response.json();

            const serviceData = data.map((service) => ({
                id: service.id,
                name: service.name,
            }));

            this.setState({ services: serviceData });
        } catch (error) {
            console.log(error)
        }
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AreaOfExpertise
    *   @Method                     :       handleSelect
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this set the state of service in id or name format
    *********************************************************************************************************
    */

    handleSelect = (selectedList, selectedItem) => {
        const selectedServices = selectedList.map((service) => ({
            id: service.id,
            name: service.name,
        }));
        this.setState({ selectedServices: selectedServices });
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AreaOfExpertise
    *   @Method                     :       handleRemove
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this remove the selected service from the form 
    *********************************************************************************************************
    */

    handleRemove = (selectedList, selectedItem) => {
        if (!selectedItem) {
            return;
        }
        const updatedServices = this.state.selectedServices.filter(
            (service) => service.id !== selectedItem.id
        );
        this.setState({ selectedServices: updatedServices });
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AreaOfExpertise
    *   @Method                     :       componentDidMount
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this get all services or areaOfExperties data initialy or any updation
    *********************************************************************************************************
    */

    componentDidMount() {
        this.getServiceData();
        this.fetchPosts();
    }

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AreaOfExpertise
    *   @Method                     :       setNullAllInputField
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       set all input value null 
    *********************************************************************************************************
    */

    setNullAllInputField = () => {
        this.setState((prevState) => ({
            areaOfExpertiseForm: {
                ...prevState.areaOfExpertiseForm,
                name: {
                    ...prevState.areaOfExpertiseForm.name,
                    value: '',
                },
            }
        }));
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AreaOfExpertise
    *   @Method                     :       onAreaOfExpertise
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this mfunction save or update all input data in the database
    *********************************************************************************************************
    */

    onAreaOfExpertise = (event) => {
        event.preventDefault();
        const { name } = this.state.areaOfExpertiseForm;
        const requestData = {
            name: name.value,
            serviceId: this.state.selectedServices.map((service) => service.id),
            createdBy: localStorage.getItem('userId'),
            updatedBy: localStorage.getItem('userId'),
        };
        if (this.state.editPostBtn) {
            fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/areaofexpertise/` + this.state.editPostId, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then(res => {
                    res.json()
                })
                .then(data => {
                    this.toggleForm();
                    this.fetchPosts();
                    this.setNullAllInputField();
                    this.setState({ selectedServices: [] });
                    this.setState({
                        editPostBtn: false,
                        editPostId: null,
                    });
                })
                .catch(err => {
                    console.error('Error updating testimonial:', err);
                });
        } else {
            fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/areaofexpertise`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then(res => { return res })
                .then(data => {
                    if (data.status === 500) {
                        alert("Internal server error ")
                    }
                    this.toggleForm();
                    this.fetchPosts();
                    this.setState({ selectedServices: [] });
                    this.setNullAllInputField();
                })
                .catch(err => {
                    console.error('Error creating testimonial:', err);
                });
        }
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AreaOfExpertise
    *   @Method                     :       fetchPosts
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this method call api for fetch areaOfExpertises data from the server
    *********************************************************************************************************
    */

    fetchPosts = () => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/areaofexpertise`)
            .then((res) => res.json())
            .then((resData) => {
                this.setState({ posts: resData, postsLoading: false });
            })
            .catch((err) => {
                console.error('Error fetching blog posts:', err);
                this.setState({ postsLoading: false });
            });
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AreaOfExpertise
    *   @Method                     :       deletePostHandler
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this method using for the delete the areaOfExpertise
    *********************************************************************************************************
    */

    deletePostHandler = (postId) => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/areaofexpertise/${postId}`, {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + this.props.token,
            },
        })
            .then((res) => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Deleting a post failed!');
                }
                console.log('Post deleted successfully');
                this.fetchPosts();
                return res.json();
            })
            .catch((err) => {
                console.error('Error deleting post:', err);
                this.setState({ postsLoading: false });
            });
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AreaOfExpertise
    *   @Method                     :       inputBlurHandler
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this method used for blur functionality of input 
    *********************************************************************************************************
    */

    inputBlurHandler = (input) => {
        this.setState((prevState) => ({
            areaOfExpertiseForm: {
                ...prevState.areaOfExpertiseForm,
                [input]: {
                    ...prevState.areaOfExpertiseForm[input],
                    touched: true,
                },
            },
        }));
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AreaOfExpertise
    *   @Method                     :       fetchSinglePost
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       fetch single post data from the server 
    *********************************************************************************************************
    */

    fetchSinglePost = (postId) => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/areaofexpertise/` + postId)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Failed to fetch post data.');
                }
                return res.json();
            })
            .then(resData => {
                const array = resData.service.map(service => ({
                    id: service.id,
                    name: service.name
                }))
                const selectedServices = array.map((service) => service.id);
                this.setState(prevState => ({
                    areaOfExpertiseForm: {
                        ...prevState.areaOfExpertiseForm,
                        name: {
                            ...prevState.areaOfExpertiseForm.name,
                            value: resData.name
                        },
                    },
                    selectedServices: selectedServices,
                }));
                this.handleRemove(resData.services);
                this.handleSelect(resData.services);
            })
            .catch(error => {
                console.error('Error fetching post data:', error);
            })
            .finally(() => {
                this.setState({ postsLoading: false });
            });
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       AreaOfExpertise
    *   @Method                     :       editPost
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this function open the form for editing or render all data in input field 
    *********************************************************************************************************
    */

    editPost = postId => {
        this.setState({
            editPostBtn: true,
            editPostId: postId
        }, () => {
            this.fetchSinglePost(postId);
            this.toggleForm();
        });
    };

    render() {
        return (
            <div className="main-div-login">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '1rem 0 ',
                    }}>
                    <button onClick={this.toggleForm} className='buttonCreatePost'>
                        {this.state.formShow ? 'Close New Post' : 'Create New Post'}
                    </button>
                </div>
                <div>
                    <ImageUrlGenerator />
                </div>
                {this.state.formShow && (
                    <Auth>
                        <form onSubmit={this.onAreaOfExpertise}>
                            <Input
                                id="name"
                                label="AreaOfExpertise  Name"
                                type="text"
                                control="input"
                                onChange={this.inputChangeHandler}
                                value={this.state.areaOfExpertiseForm['name'].value}
                                valid={this.state.areaOfExpertiseForm['name'].valid}
                            />
                            <form className="row g-3" method="post" style={{ margin: '1rem 0' }}>
                                <div className="">
                                    <label className="form-label">Select Service</label>
                                    <div className="text-dark">
                                        <Multiselect
                                            isObject
                                            onSelect={this.handleSelect}
                                            options={this.state.services}
                                            onRemove={this.handleRemove}
                                            displayValue="name"
                                            showCheckbox={true}
                                            selectedValues={this.state.selectedServices}
                                        />
                                    </div>
                                </div>
                            </form>
                            {this.state.editPostBtn ? (
                                <Button design="raised" type="submit" loading={this.props.loading}>
                                    Update Post
                                </Button>
                            ) : (
                                <Button design="raised" type="submit" loading={this.props.loading}>
                                    Create Post
                                </Button>
                            )}
                        </form>
                    </Auth>
                )}
                <Auth>
                    {this.state.postsLoading && <Loader />}
                    {this.state.posts.length <= 0 && !this.state.postsLoading ? (
                        <p style={{ textAlign: 'center' }}>No posts found.</p>
                    ) : null}
                    {this.state.posts.map((post) => (
                        <Post
                            key={post.id}
                            id={post.id}
                            title={post.name}
                            onStartEdit={() => this.editPost(post.id)}
                            onDelete={() => this.deletePostHandler(post.id)}
                        />
                    ))}
                </Auth>
            </div>
        );
    }
}

export default AreaOfExpertise;


