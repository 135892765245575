import React, { useEffect, useState } from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { gapi } from 'gapi-script';
import { useNavigate } from 'react-router-dom';
import './css/signinForm.css';
import LoaderForHrm from './LoaderForHrm';
const clientId = process.env.REACT_APP_PRODUCTION_GOOGLE_OATH_CLIENT_ID;

/*
*********************************************************************************************************
*  @Parent Component           :    App
*  @React Component Name       :    LoginForm_forHrm
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    04-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Login Component for Hrm Panel 
********************************************************************************************************
*/

const LoginForm_forHrm = ({ onLogin }) => {
  const navigate = useNavigate();
  const [authGoogleTrue, setAuthGoogleTrue] = useState(false);
  const [loading, setLoading] = useState(false);
  /*
  *********************************************************************************************************
  *   @React Component Name       :       LoginForm_forHrm
  *   @Method                     :       onSuccess
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       User is autorised from the google then check in the Backend Db and salesForce contact List if it authorised user then set the client cridiential on the localhost  
  *********************************************************************************************************
  */
  const onSuccess = async (credentialResponse) => {
    setLoading(true);
    try {
      const USER_CREDENTIAL = jwtDecode(credentialResponse.credential);
      const userEmail = USER_CREDENTIAL.email;
      const requestData = {
        email: userEmail,
        password: "123456",
      };
      const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/Login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
      if (response.ok) {
        const data = await response.json();
        if (data && data.emailIdOfMail) {
          localStorage.setItem('contactUserId', data.idOfExistsUser);
          localStorage.setItem('contactUserName', data.nameofExistsUser);
          localStorage.setItem('authTokenForGoogleLogin', 'true');
          setAuthGoogleTrue(true);
          onLogin();
          navigate('/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm');
        }
        else {
          console.error('User ID not found in response data.');
        }
      }
      else {
        const errorText = await response.text();
        console.error('Failed to create internal user:', response.statusText, errorText);
      }
    }
    catch (error) {
      console.error('Error checking email or creating user:', error);
    }
    finally {
      setLoading(false);
    }
  };

  /*
  *********************************************************************************************************
  *   @React Component Name       :       LoginForm_forHrm
  *   @Method                     :       useEffect
  *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
  *   @Company                    :       Antrazal
  *   @Description                :       Load the loadGapiScript when the user on this login page and show authentication section 
  *********************************************************************************************************
  */

  useEffect(() => {
    const loadGapiScript = () => {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';
      script.async = true;
      script.onload = () => {
        gapi.load('client:auth2', start);
      };
      document.body.appendChild(script);
    };

    const start = () => {
      gapi.client.init({
        clientId: clientId,
        scope: 'email'
      }).then(() => {
      }).catch((error) => {
        console.error('Error initializing GAPI client', error);
      });
    };
    loadGapiScript();
  }, []);

  return (
    <div className='container' style={{ marginTop: "8rem", marginBottom: "3rem" }}>
      <h1 style={{ color: "#0036db", fontSize: "2rem" }}>Sign In With Google</h1>
      <LoaderForHrm loading={loading} />
      <GoogleOAuthProvider clientId={clientId}>
        <div id="signInButton">
          <GoogleLogin
            clientId={clientId}
            buttonText="Login"
            onSuccess={onSuccess}
            onError={() => {
              // console.log('Login Failed');
            }}
            cookiePolicy={'single_host_origin'}
            isSignedIn={true}
          />
        </div>
      </GoogleOAuthProvider>
    </div>
  );
};

export default LoginForm_forHrm;
