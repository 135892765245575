import React, { Component } from "react";
import "../Components/css/setmeeting.css";
import { required, email } from '../adminPanelSRC/util/validators';
import { Container, Col, Row } from "react-bootstrap";

/*
*********************************************************************************************************
*  @Parent Component           :    Service Component
*  @React Component Name       :    SetMeeting
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Set meeting form
********************************************************************************************************
*/

export default class SetMeeting extends Component {

	constructor(props) {
		super(props);
		this.state = {
			onsubmitContactUsForm: {
				phonenumber: {
					value: '',
					valid: false,
					touched: false,
					validators: []
				},
				email: {
					value: '',
					valid: false,
					touched: false,
					validators: [required, email]
				},

			},
			formIsValid: false
		};
	}

	/*
	*********************************************************************************************************
	*   @React Component Name       :       SetMeeting
	*   @Method                     :       setNullAllInputField
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       Set all value null 
	*********************************************************************************************************
	*/

	setNullAllInputField = () => {
		this.setState((prevState) => ({
			onsubmitContactUsForm: {
				...prevState.onsubmitContactUsForm,
				phonenumber: {
					...prevState.onsubmitContactUsForm.phonenumber,
					value: '',
				},
				email: {
					...prevState.onsubmitContactUsForm.email,
					value: '',
				},

			}
		}));
	};

	/*
	*********************************************************************************************************
	*   @React Component Name       :       SetMeeting
	*   @Method                     :       inputBlurHandler
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       This method is an event handler designed to handle
	*********************************************************************************************************
	*/

	inputBlurHandler = input => {
		this.setState(prevState => {
			return {
				onsubmitContactUsForm: {
					...prevState.onsubmitContactUsForm,
					[input]: {
						...prevState.onsubmitContactUsForm[input],
						touched: true
					}
				}
			};
		});
	};

	/*
	*********************************************************************************************************
	*   @React Component Name       :       SetMeeting
	*   @Method                     :       inputChangeHandler
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       It set the value onchange in the input field 
	*********************************************************************************************************
	*/

	inputChangeHandler = (input, value) => {
		this.setState(prevState => {
			const updatedForm = {
				...prevState.onsubmitContactUsForm,
				[input]: {
					...prevState.onsubmitContactUsForm[input],
					value: value,
					valid: prevState.onsubmitContactUsForm[input].validators.every(validator => validator(value)),
					touched: true
				}
			};
			let formIsValid = true;
			for (const inputName in updatedForm) {
				formIsValid = formIsValid && updatedForm[inputName].valid;
			}
			return {
				onsubmitContactUsForm: updatedForm,
				formIsValid: formIsValid
			};
		});
	};

	/*
	*********************************************************************************************************
	*   @React Component Name       :       SetMeeting
	*   @Method                     :       onSubmitForm
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       this method call the api and save the data in the Db
	*********************************************************************************************************
	*/

	onSubmitForm = (event) => {
		event.preventDefault();
		const { phonenumber, email } = this.state.onsubmitContactUsForm;
		const requestData = {
			phonenumber: phonenumber.value,
			email: email.value,
			createdBy: email.value,
			updatedBy: email.value,
		};
		fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/Leets`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(requestData),
		})
			.then((res) => res.json())
			.then((data) => {
				this.setNullAllInputField()
			})
			.catch((err) => {
				console.error('Error submitting contact form:', err);
			});
	};

	render() {
		return (
			<>
				<section className="setbg">
					<Container style={{ maxWidth: "1255px" }}>
						<div>
							<Row className="">
								<Col style={{ padding: "4rem 1rem 1rem 2rem" }}>
									<h1 className="heading setbg_heading" style={{ color: "#1012a2" }} >
										If you have a similar project,
										<br />
										Let's discuss.
									</h1>
									<span className="underline"></span>
									<br />
									<div style={{ color: "whitesmoke" }}>
										<a href="mailto:info@antrazal.com" className="email_link" style={{ color: "black" }}><span>Email : info@antrazal.com </span></a>
									</div>
								</Col>
								<Col lg={4} xl={4} md={6} className="" style={{ padding: "5rem 0.5rem" }}>
									<div className="btn-home-container"><button className="btns2"><b><a href="https://calendly.com/info-gmgd/30min" target="_blank">Book a free Consultation</a></b></button></div>
								</Col>
							</Row>
						</div>
					</Container>
				</section>
			</>
		);
	}
}


