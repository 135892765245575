import React from 'react';
import { Container } from 'react-bootstrap';
import "../Components/css/Work.css";
/*
*********************************************************************************************************
*  @Parent Component           :     
*  @React Component Name       :    AccordionWork
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Work section
********************************************************************************************************
*/
function AccordionWork() {
    return (
        <section className='' id='work'>
            <Container style={{ paddingTop: "3rem" }}>
                <div style={{ textAlign: "end", padding: "0 18px" }} >
                    <p className="heading" style={{ color: "#1012a2" }}>How We Work </p>
                    <p className="title_sub" style={{ marginTop: "20px", padding: " 0px;", color: "rgb(64 67 221)" }}>
                        We have specific work models that deliver value and efficient service that meet the needs of your business and are committed to creating long-term valuation relations.</p>
                </div>
                <div style={{padding:"1rem 0"}}>
                    <div>
                        <h1 className='workAs-heading'>Fixed Cost</h1>
                        <p className='workAs-para'>Have a blueprint of what you need to build towards digitalizing your business, then our fixed project cost model is the best go-to-partnership approach to begin with.</p>
                    </div>
                    <div>
                        <h1 className='workAs-heading'>Hourly</h1>
                        <p className='workAs-para'>
                            Need help increasing ROI efficacy on your Salesforce and digitalization investments, our team of consultants and business experts can lead the way to significant impact.</p>
                    </div>
                    <div>
                        <h1 className='workAs-heading'>
                            Retainer</h1>
                        <p className='workAs-para'>With our Global Delivery teams working in various regions across the globe, expand your team’s strength adding full-time certified Salesforce experts, developers and/or administrators.</p>
                    </div>
                    <div className="btn-home-container"><button className="btns2"><b><a href="https://calendly.com/info-gmgd/30min" target="_blank">Let’s Discuss</a></b></button></div>
                </div>
            </Container>
        </section>
    );
}
export default AccordionWork;